@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.cdnfonts.com/css/gotham-rounded');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'URWGeometricArabic-Bold';
  src: url('./assets/font.ttf');
}

@font-face {
  font-family: 'URWGeometricArabic-Regular';
  src: url('./assets/fonts/fontRegular.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Titillium Web', sans-serif;
}

.rdw-editor-toolbar,
.toolbarClassName {
  color: #000000;
}

#rdw-wrapper-9686,
.wrapperClassName,
.rdw-editor-wrapper {
  border: 1px solid;
}

.public-DraftStyleDefault-block {
  padding: 0 10px;
}

@media only screen and (max-width: 900px) {
  body {
    cursor: auto;
  }
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

ul {
  list-style: none;
}

input:focus,
select:focus,
button:focus,
textarea:focus,
a,
a:hover,
a:focus {
  outline: none;
  text-decoration: none;
  color: unset;
}

body::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 0.8em;
}

body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  background-color: rgb(64, 65, 52);
  width: 1em;
  padding: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background-color: #e0cc57;
  opacity: 0.3;
  border: 2px solid #333;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover,
div::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
  transform: scaleX(1.45);
}

.displayFlex {
  display: flex;
}

.react-reveal {
  padding: 0 !important;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

/* .link {
  width: 50px;
  height: 30px;

  font-family: 'URWGeometricArabic-Regular';
  font-size: 20px;
  line-height: 23px;

  color: #000000;
} */

.top {
  position: absolute;
  top: 60px;
  left: 99px;
}

.payment {
  position: absolute;
  top: 60px;
  left: 0px;
}

.img {
  width: 100%;
  padding-right: 22px;
}

.testt {
  height: 550px !important;
  display: flex !important;
  flex-direction: column;
}

.text-about {
  width: 880px;
  height: 41px;
  font-family: 'URWGeometricArabic-Regular';
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #000000;
  padding: 5px;
}

.nav-logo {
  width: 100%;
}
